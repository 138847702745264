const firebaseConfig = {
  apiKey: 'AIzaSyDwv8Ab3CrCuyH2ETNNJ3AM-9tn8cryIio',
  authDomain: 'jobwus-5f24c.firebaseapp.com',
  projectId: 'jobwus-5f24c',
  storageBucket: 'jobwus-5f24c.appspot.com',
  messagingSenderId: '271834471268',
  appId: '1:271834471268:web:3d77062dec16e6bc50b871',
  measurementId: 'G-43YMKSMS0D',
};
export default firebaseConfig;
