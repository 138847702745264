<template>
  <div>
    JOBWUS ESTA <br />
    PROCESANDO DATOS...
    <br />
    <img src="/img/load.gif" alt="" />
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
div {
  font-weight: bolder;
  text-align: center;
}
</style>
