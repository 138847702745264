<template>
  <div class="cfg">
    <h1>CONFIGURACIÓN JOBWUS {{ version }}</h1>
    <hr />
    <div>
      <strong>Theme: </strong>
      <select class="button" @change="setTheme">
        <option :value="theme" v-for="theme in Object.keys(themes.styles).sort()" :key="theme" :selected="themes.themeSelected === theme">{{ theme }}</option>
      </select>
    </div>
    <div>
      <strong> Zoom: </strong>
      <select class="button" @change="setZoom">
        <option :value="zoom" v-for="zoom in Object.keys(themes.zooms)" :key="zoom" :selected="themes.themeZoom === zoom">{{ zoom }}</option>
      </select>
    </div>
    <hr />
    <div>
      <strong>Voz: </strong>
      <select style="max-width: 100%" class="button" @change="setVoice">
        <option v-for="item in voiceList" :key="item" :value="item" :selected="item === voice">{{ item === '' ? 'ninguna' : item }}</option>
      </select>
      <span v-if="voice !== ''">
        <br /><br />
        <strong>Rapidez de lectura: </strong>
        <select class="button" @change="setSpeed">
          <option :value="speed" v-for="speed in speeds" :key="speed" :selected="speed === voiceSpeed">{{ speed * 100 }}%</option>
        </select>
      </span>
    </div>

    <hr />

    <input type="checkbox" @click.stop="" id="ignorarTildes" v-model="ignorarTildes" />
    <label style="position: relative; top: -0px !important; margin-left: 10px" class="menu-button" @click.stop="" for="ignorarTildes">Ignorar tildes en la busqueda.</label>
    <br />
    <hr />
    <button class="button" @click.stop="importConfig"><download-icon class="buttonCfg" />Importar Localstorage</button>&nbsp; <button @click.stop="exportConfig" class="button"><upload-icon class="buttonCfg" />Exportar Localstorage</button>&nbsp; <button @click.stop="clearConfig" class="button"><close-octagonoutline-icon class="buttonCfg" />Limpiar Localstorage</button>&nbsp;
    <button @click.stop="update" class="button"><progress-download-icon class="buttonCfg" />Actualizar App</button>
    <!-- <button @click.stop="logout" class="button"><upload-icon class="buttonCfg" />LOGOUT</button> -->
    <!-- <button class="button">undo</button>
    <button class="button">redo</button> -->
    <br />
    <br />
    <hr />
    <p></p>

    <h1>¿QUE ES JOBWUS?</h1>
    <br />

    Es un extractor de ofertas laborales desde los siguientes portales de empleos en Chile:<br /><br />
    <div style="background: #676; font-weight: bolder; font-size: 18px"><a href="#" @click="go('https://www.bne.cl', true)">BNE</a> &nbsp; <a href="#" @click="go('https://www.bne.cl/ofertas?mostrar=empleo-externo&numPaginaRecuperar=1', true)">BNE EXTERNO</a> &nbsp; <a href="#" @click="go('ttps://www.chiletrabajos.cl', true)">CHILETRABAJOS</a> &nbsp; <a href="#" @click="go('https://www.empleospublicos.cl', true)">EMPLEOSPUBLICOS</a> &nbsp; <a href="#" @click="go('https://www.getonbrd.com/', true)">GETONBRD</a></div>
    <br /><br />
    <!-- <a style="text-decoration: line-through" href="#" @click="go('https://www.trabajando.cl', true)">trabajando.cl</a><br />
      <a style="text-decoration: line-through" href="#" @click="go('https://www.computrabajo.cl', true)">computrabajo.cl</a><br /> -->

    Finalizada la extracción, las ofertas son filtradas y agrupadas en las siguientes categorias:<br /><br />
    <div style="background: #677; font-weight: bolder; font-size: 18px"><a href="#" @click="go('/compliance')">COMPLIANCE</a> &nbsp; <a href="#" @click="go('/info')">INFORMÁTICA</a> &nbsp; <a href="#" @click="go('/sts')">TRABAJO SOCIAL</a> &nbsp; <a href="#" @click="go('/profes')">PROFESORES</a></div>
    <br /><br />

    <p>Dentro de cada categoria se calcula la similitud entre ofertas mediante <a href="#" @click="go('https://github.com/stephenjjbrown/string-similarity-js', true)" target="_blank">string-similarity-js</a> y se agrupan ofertas similares usando <a href="#" @click="go('https://github.com/deestan/set-clustering', true)" target="_blank">set-clustering</a></p>
    <br />
    <p>El agrupamiento que se realiza 2 veces al día retorna grupos de ofertas similares, pero más útil sería poder identificar con seguridad las ofertas duplicadas. En un futuro esto se podrá mejorar utilizando aprendizaje automático, que tambien ayudara a hacer un mejor match de las ofertas seleccionadas.</p>
    <br />
    <p>
      El repositorio github de la parte frontend del proyecto esta en<br />
      <a href="#" @click="go('https://github.com/fabnun/vue-jobwus', true)" target="_blank">fabnun/vue-jobwus</a>
    </p>
    <br /><br />
    <hr />
    <div>
      <h3>Ejemplos de filtros de busqueda:</h3>
      <br />
      <ul>
        <li>* matematicas? & valparaiso</li>
        <li>* java, javascript, node</li>
        <li>* vue( ?js)? & mongo( ?db)?</li>
      </ul>
    </div>

    <div style="text-align: center; height: 100px">
      <a href="#" @click="go('https://twitter.com/jobwus', true)" style="text-decoration: none" target="_blank">
        <twitter-icon class="button-icon" />
        <span style="position: relative; top: -6px">@jobwus</span>
      </a>
    </div>
  </div>
</template>
<script>
import { initializeApp } from 'firebase/app';
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import firebaseConfig from '../firebaseConfig';
initializeApp(firebaseConfig);
const auth = getAuth();
import DownloadIcon from 'vue-material-design-icons/Download.vue';
import UploadIcon from 'vue-material-design-icons/Upload.vue';
import CloseOctagonoutlineIcon from 'vue-material-design-icons/CloseOctagonOutline.vue';
import twitterIcon from 'vue-material-design-icons/Twitter.vue';
import ProgressDownloadIcon from 'vue-material-design-icons/ProgressDownload.vue';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import pjson from '../../package.json';

export default {
  props: ['words', 'voiceList'],
  components: {
    DownloadIcon,
    UploadIcon,
    CloseOctagonoutlineIcon,
    twitterIcon,
    ProgressDownloadIcon,
  },
  data() {
    return {
      version: pjson.version,
      device: 'android',
      themes: require('../themes.js').default,
      speeds: [1, 1.25, 1.5, 1.75, 2],
      oldCfg: this.$store.state.words,
      voiceSpeed: parseFloat(this.localGetItem('voiceSpeed')) || 1,
      voice: this.localGetItem('voice') || null,
    };
  },
  methods: {
    update() {
      if (window.caches) {
        caches.keys().then((names) => {
          for (let name of names) {
            console.log('borrando cache', name);
            caches.delete(name);
          }
        });
        const now = Date.now();
        localStorage.setItem('__updated', now);
        window.location.reload();
      }
    },
    go(url, blank = false) {
      if (blank) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    },
    logout() {
      sendPasswordResetEmail(auth).then(
        () => {
          this.notification('Enviamos un email para reestablecer su clave, revise su correo ');
        },
        (error) => this.notification(error, 'error')
      );
    },
    setTheme(e) {
      this.themes.setTheme(e.target.value);
    },
    setZoom(e) {
      this.themes.setZoom(e.target.value);
    },
    setVoice(e) {
      this.voice = e.target.value;
      this.localSetItem('voice', this.voice);
      this.$emit('setVoice', e.target.value);
    },
    setSpeed(e) {
      this.voiceSpeed = parseFloat(e.target.value);
      this.localSetItem('voiceSpeed', this.voiceSpeed);
      this.$emit('setSpeed', e.target.value);
    },
    setCfg(e) {
      if (confirm('¿Estás seguro de que quieres cambiar las palabras clave?')) {
        this.localSetItem('cfg', e.target.value);
        location.reload();
      } else {
        e.target.value = this.oldCfg;
      }
    },
    importConfig() {
      let fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.addEventListener('change', (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
          let config = JSON.parse(e.target.result);
          this.localSetItem('ignorarTildes', config.ignorarTildes);
          this.localSetItem('folder', config.folder);
          this.localSetItem('zoom', config.zoom);
          this.localSetItem('theme', config.theme);
          this.localSetItem('voice', config.voice);
          this.localSetItem('voiceSpeed', config.voiceSpeed);
          this.localSetItem('filtro', config.filtro);
          this.localSetItem('searchConfig', config.searchConfig);
          this.localSetItem('searchListSelect', config.searchListSelect);
          this.localSetItem('comments', config.comments, true);
          this.localSetItem('favoritos', config.favoritos, true);
          this.localSetItem('archivados', config.archivados, true);
          location.reload();
        };
        reader.readAsText(file);
      });
      fileInput.click();
      //this.$store.dispatch('importConfig');
    },
    clearConfig() {
      window.localStorage.clear();
      location.reload();
    },
    exportConfig() {
      let config = {
        ignorarTildes: this.localGetItem('ignorarTildes'),
        folder: this.localGetItem('folder'),
        zoom: this.localGetItem('zoom'),
        theme: this.localGetItem('theme'),
        voice: this.localGetItem('voice'),
        voiceSpeed: this.localGetItem('voiceSpeed'),
        filtro: this.localGetItem('filtro'),
        searchConfig: this.localGetItem('searchConfig'),
        searchListSelect: this.localGetItem('searchListSelect'),
        comments: this.localGetItem('comments', true),
        favoritos: this.localGetItem('favoritos', true),
        archivados: this.localGetItem('archivados', true),
      };
      const jsonString = JSON.stringify(config);

      (async () => {
        if (this.device.platform === 'web') {
          let blob = new Blob([jsonString], { type: 'application/json' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.type = 'application/json';
          link.download = 'jobwus.localstorage.json';
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          const result = await Filesystem.writeFile({
            path: 'jobwus.localstorage.json',
            data: jsonString,
            directory: Directory.Documents,
            encoding: Encoding.UTF8,
          });
          alert('Archivo guardado en: ' + result.uri.substring(7));
        }
      })();
    },
  },
  mounted() {
    this.device = window.deviceCapacitor;
    this.voice = this.localGetItem('voice') || '';
    this.voiceSpeed = parseFloat(this.localGetItem('voiceSpeed') || 1);
    this.$emit('setVoice', this.voice);
    this.$emit('setSpeed', this.voiceSpeed);
  },
  computed: {
    ignorarTildes: {
      get() {
        return this.$store.state.ignorarTildes;
      },
      set() {
        this.$store.commit('setIgnorarTildes');
      },
    },
  },
};
</script>
<style scoped>
.buttonCfg {
  position: relative;
  top: 4px;
}
.cfg {
  font-size: 0.9em;
}
.button {
  margin: 4px;
  outline: none;
  border: 1.5px solid var(--color);
  border-radius: var(--radio);
  cursor: pointer;
  padding: 0.2em 0.1em;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
